<section class="chronos-dialog--size-md">
  <app-multi-user-worktime-table
    [article]="article"
    [manualModeFinishData]="manualModeFinishData"
    [form]="form"
    (quantityPanelChanged)="panelChange($event)"
    (formStatusChanged)="formStatusChange($event)"
  ></app-multi-user-worktime-table>
  <lib-modal-footer
    [submitLabel]="submitLabel"
    [whenLoadingTopic]="LOADING_TOPIC"
    (submitClicked)="submitButton()"
    (declineClicked)="cancelButton()"
    [submitDisabled]="!isFormValid"
  ></lib-modal-footer>
</section>
