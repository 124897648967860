import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CounterInfo } from 'chronos-core-client';

function createInitialState(): Partial<CounterInfo> {
  return {
    counter: null,
    itemsPerHour: null,
    counterUnitId: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'machine-counter' })
export class MachineCounterStore extends Store<CounterInfo> {
  constructor() {
    super(createInitialState());
  }
}
