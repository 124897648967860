import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActiveProductionOrder,
  ArticleDescription,
  ManualMachineCheckoutPageMode,
  ProductionOrderManualModeFinishingDataViewModel,
  Quantity,
  RunEmployeeWorkTimeData
} from 'chronos-core-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ManualModeService } from '@app/modules/manual-mode/services/manual-mode/manual-mode.service';
import moment from 'moment-mini';
import { nav, notificationTopic } from '@app/shared/utils';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { LoadingNotificationService, LogService } from 'chronos-shared';
import { Subscription } from 'rxjs';
import { OutputFinish, OutputFinishState } from '@app/modules/run-phase/models';
import { StatusPanel } from '../../models';

@Component({
  selector: 'app-order-finish-multiuser-modal',
  templateUrl: './order-finish-multiuser-modal.component.html',
  styleUrls: ['./order-finish-multiuser-modal.component.scss']
})
export class OrderFinishMultiUserModalComponent implements OnInit, OnDestroy {
  public article: ArticleDescription;
  public form: UntypedFormGroup;

  public outputFinish?: OutputFinish;
  public submitLabel = '';
  public orderGrossQuantity: Quantity;
  public qtyStatusPanel: StatusPanel;
  public manualModeFinishData: ProductionOrderManualModeFinishingDataViewModel;
  public activeProductionOrder: ActiveProductionOrder;
  private runEmployeeWorkTimeData: RunEmployeeWorkTimeData[];
  private isFromPallet: false;
  public isFormValid: boolean;
  private subscriptions = new Subscription();

  public readonly LOADING_TOPIC = notificationTopic.modalForManualMode;
  public readonly HOURS_MAX_LIMIT = 838;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private router: Router,
    private manualModeService: ManualModeService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      rows: this.fb.array([])
    });
  }

  public ngOnInit(): void {
    this.manualModeFinishData = this.config.data?.sortingValues;
    this.activeProductionOrder = this.config.data?.activeProductionOrder;
    this.orderGrossQuantity = this.manualModeFinishData?.orderGrossQuantity;
    this.article = this.config.data?.article;
    this.submitLabel = this.config.data?.submitLabel;
    this.outputFinish = this.config.data?.outputFinish;
    this.isFromPallet = this.config.data?.isFromPallet;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public submitButton(): void {
    if (!this.form.valid) return;

    if (this.qtyStatusPanel.totalHours > this.HOURS_MAX_LIMIT) {
      LogService.warn('FINISH_ORDER_MODAL.MAX_HOURS_VALIDATION');
      return;
    }

    this.runEmployeeWorkTimeData = this.createFormValues(this.form.value);

    const endTime = moment().toDate();
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    this.manualModeService
      .saveManualModeFinishingValues(
        ManualMachineCheckoutPageMode.MULTI_USER,
        this.activeProductionOrder.productionOrderId,
        this.runEmployeeWorkTimeData
      )
      .subscribe(
        () => {
          if (!this.isFromPallet) {
            LoadingNotificationService.publish(this.LOADING_TOPIC, false);
            this.ref.close(true);
            return;
          }

          if (this.outputFinish?.finishState === OutputFinishState.Switch) {
            this.manualModeService
              .switchManualOrder(
                ManualMachineCheckoutPageMode.MULTI_USER,
                this.activeProductionOrder.productionOrderId,
                this.outputFinish?.producedMaterialId,
                endTime,
                null,
                this.runEmployeeWorkTimeData
              )
              .pipe(
                finalize(() => {
                  LoadingNotificationService.publish(this.LOADING_TOPIC, false);
                })
              )
              .subscribe(() => {
                this.finishOrder();
              });
          } else {
            this.manualModeService
              .endManualSortingOrder(
                this.activeProductionOrder.productionOrderId,
                endTime,
                this.outputFinish?.finishState === OutputFinishState.Interrupt
              )
              .pipe(
                finalize(() => {
                  LoadingNotificationService.publish(this.LOADING_TOPIC, false);
                })
              )
              .subscribe(() => {
                this.finishOrder();
              });
          }
        },
        () => {
          this.ref.close(true);
        }
      );
  }

  public cancelButton(): void {
    this.ref.close(false);
  }

  public formStatusChange(formStatus: boolean) {
    this.isFormValid = formStatus;
  }

  public panelChange(statusPanel: StatusPanel) {
    this.qtyStatusPanel = statusPanel;
  }

  private finishOrder(): void {
    LogService.success('SUCCESS_MESSAGE.JOB_FINISHED');
    this.navigateToFinishPhase();
  }

  private createFormValues(formData) {
    return formData.rows.map((element) => ({
      employeeId: element.employee.id,
      duration: this.formatDate(element.minutes, element.hours),
      quantity: { value: element.quantity, unitId: this.orderGrossQuantity.unitId }
    }));
  }

  private formatDate(minutes: number, hours: number): any {
    const totalDays = Math.floor(hours / 24);
    const remainingHours = hours % 24;

    const timeSpan = `${totalDays > 0 ? `${totalDays}.` : ''}${remainingHours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:00`;

    console.info(timeSpan);

    return timeSpan;
  }

  private navigateToFinishPhase(): void {
    this.router.navigate([nav.finish]).then(() => {
      this.ref.close(true);
    });
  }
}
