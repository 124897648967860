import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ActiveProductionOrder, ProducedMaterialType, RunPhaseType, RunSubPhaseType } from 'chronos-core-client';
import { isNil } from 'ramda';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActiveOrderStore } from './active-order.store';

@Injectable({
  providedIn: 'root'
})
export class ActiveOrderQuery extends Query<ActiveProductionOrder> {
  public activeOrder$: Observable<ActiveProductionOrder> = this.select().pipe(map((entity) => this.productionOrderOrNull(entity)));
  public activeOrderPhase$: Observable<RunPhaseType> = this.select((entity) => entity.runPhaseType);
  public activeOrderSubPhase$: Observable<RunSubPhaseType> = this.select((entity) => entity.runSubPhaseType);
  public activeOrderId$: Observable<number> = this.select((entity) => entity.productionOrderId);
  public areKpisConfirmed$: Observable<boolean> = this.select((entity) => entity.areKpisConfirmed);
  public isQaHistoryConfirmed$: Observable<boolean> = this.select((entity) => entity.isQaHistoryConfirmed);
  public manualMachineMode$: Observable<string> = this.select((entity) => entity.manualMachineMode);
  public operationName$: Observable<string> = this.select((entity) => entity.operationName);
  public isQaToolCheckoutConfirmed$: Observable<boolean> = this.select((entity) => entity.isQaToolCheckoutConfirmed);

  constructor(protected store: ActiveOrderStore) {
    super(store);
  }

  public getActiveOrderSubPhase(): RunSubPhaseType {
    return this.getValue().runSubPhaseType;
  }

  public selectRunPhaseStartCounter(): Observable<number | null> {
    return this.select((entity) => entity.runPhaseStartCounterValue ?? null);
  }

  public getActiveOrderId(): number {
    return this.getValue().productionOrderId;
  }

  public getActiveOrder(): ActiveProductionOrder {
    return this.productionOrderOrNull(this.getValue());
  }

  private productionOrderOrNull(entity: ActiveProductionOrder): ActiveProductionOrder | null {
    return !isNil(entity.productionOrderId) ? entity : null;
  }

  public getActiveOrderType(): ProducedMaterialType {
    return this.getValue().producedMaterialType;
  }

  public getActiveOrderStatus(): string {
    return this.getValue().status;
  }
}
