import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  AddWasteAssignmentToProducedMaterialEntry,
  ChangeContainerBobbinQuantityEntry,
  ChangeContainerQuantityEntry,
  ContainersService,
  CreateContainerFromPoolEntry,
  CreateManualPalletData,
  DeleteWasteAssignmentFromProducedMaterialEntry,
  ManualMachineCheckoutPageMode,
  PrintLabelEntry,
  Quantity,
  RunEmployeeWorkTimeData,
  SetContainerTargetBobbinQuantityEntry,
  SwitchManualProductionOrderEntry
} from 'chronos-core-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContainersDsService {
  constructor(private containersService: ContainersService, private activeWorkCenterService: ActiveWorkCenterService) {}

  public addProducedMaterialWasteAssignment(
    producedMaterialId: number,
    maculature: Quantity,
    waste: Quantity,
    wasteReasonId?: number
  ): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: AddWasteAssignmentToProducedMaterialEntry = {
      workCenterId,
      wasteReasonId,
      maculature,
      waste
    };
    const param: ContainersService.AddWasteAssignmentToProducedMaterialParams = {
      producedMaterialId,
      entry
    };
    return this.containersService.addWasteAssignmentToProducedMaterial(param);
  }

  public deletePalletWasteAssignment(producedMaterialId: number, wasteAssignmentId: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: DeleteWasteAssignmentFromProducedMaterialEntry = {
      wasteAssignmentId,
      workCenterId
    };
    const param: ContainersService.DeleteWasteAssignmentFromProducedMaterialParams = {
      producedMaterialId,
      entry
    };
    return this.containersService.deleteWasteAssignmentFromProducedMaterial(param);
  }

  public getManualContainerCreationData(productionOrderId: number): Observable<CreateManualPalletData> {
    return this.containersService.getManualContainerCreationData(productionOrderId);
  }

  public createOutputPalletFromPool(productionOrderId: number, containerTargetQuantity?: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: CreateContainerFromPoolEntry = {
      workCenterId,
      containerTargetQuantity
    };
    const params: ContainersService.CreateContainerFromPoolParams = {
      productionOrderId,
      entry
    };
    return this.containersService.createContainerFromPool(params);
  }

  public changeContainerQuantity(producedMaterialId: number, quantity: Quantity, changeForFollowingPallets = false): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: ChangeContainerQuantityEntry = {
      changeForFollowingPallets,
      newContainerQuantity: quantity.value,
      newContainerQuantityUnitId: quantity.unitId,
      workCenterId
    };
    const params: ContainersService.ChangeContainerQuantityParams = {
      producedMaterialId,
      entry
    };
    return this.containersService.changeContainerQuantity(params);
  }

  public printLabel(containerId: number, isReprint: boolean): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: PrintLabelEntry = {
      isReprint,
      workCenterId
    };
    const params: ContainersService.PrintLabelParams = {
      entry,
      containerId
    };
    return this.containersService.printLabel(params);
  }

  public switchManualOrder(
    manualMachineCheckoutPageMode: ManualMachineCheckoutPageMode,
    productionOrderId: number,
    producedMaterialId: number,
    orderEndTime: Date,
    orderStartTime?: Date,
    runEmployeeWorkTimes?: RunEmployeeWorkTimeData[],
    numberOfWorkers?: number,
    duration?: string
  ): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: SwitchManualProductionOrderEntry = {
      manualMachineCheckoutPageMode,
      endTime: orderEndTime.toISOString(),
      producedMaterialId,
      startTime: orderStartTime?.toISOString(),
      workCenterId,
      numberOfWorkers,
      duration,
      runEmployeeWorkTimes
    };
    const params: ContainersService.SwitchManualProductionOrderParams = {
      entry,
      productionOrderId
    };
    return this.containersService.switchManualProductionOrder(params);
  }

  public changeContainerBobbinQuantity(
    producedMaterialId: number,
    bobbinQuantity: number,
    bobbinQuantityUnit: string,
    changeFollowingPallets: boolean
  ): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: ChangeContainerBobbinQuantityEntry = {
      newContainerBobbinQuantity: bobbinQuantity,
      newContainerBobbinQuantityUnitId: bobbinQuantityUnit,
      changeForFollowingPallets: changeFollowingPallets,
      workCenterId
    };
    const params: ContainersService.ChangeContainerBobbinQuantityParams = {
      producedMaterialId,
      entry
    };
    return this.containersService.changeContainerBobbinQuantity(params);
  }

  public setOutputPoolBobbinQuantity(productionOrderId: number, bobbinQuantity: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: SetContainerTargetBobbinQuantityEntry = {
      containerTargetBobbinQuantity: bobbinQuantity,
      workCenterId
    };
    const params: ContainersService.SetContainerTargetBobbinQuantityParams = {
      productionOrderId,
      entry
    };
    return this.containersService.setContainerTargetBobbinQuantity(params);
  }
}
