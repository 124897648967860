import { Injectable } from '@angular/core';
import {
  ActiveEmployeeRegistration,
  ActiveEmployeeRegistrationData,
  EmployeeInfo,
  EmployeeRegistrationHistory,
  EmployeeRegistrationLoginDetails,
  EmployeeRegistrationLoginEntry,
  EmployeeRegistrationLogoutEntry,
  EmployeeRegistrationService,
  EmployeeRegistrationShift,
  EmployeeRegistrationUpdateEntry,
  EmployeeRole
} from 'chronos-core-client';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class EmployeeRegistrationDsService {
  constructor(private employeeRegistrationService: EmployeeRegistrationService) {}

  public getActiveLogin(workCenterId: number): Observable<ActiveEmployeeRegistration> {
    return this.employeeRegistrationService.getActiveLogin(workCenterId);
  }

  public getActiveEmployeeRegistrationData(workCenterId: number): Observable<ActiveEmployeeRegistrationData> {
    return this.employeeRegistrationService.getActiveEmployeeRegistrationData(workCenterId);
  }

  public getLoginDetails(workCenterId: number, employeeId: number): Observable<EmployeeRegistrationLoginDetails> {
    const params: EmployeeRegistrationService.GetLoginDetailsParams = {
      workCenterId,
      employeeId
    };
    return this.employeeRegistrationService.getLoginDetails(params);
  }

  public getShiftsForFilter(workCenterId: number): Observable<EmployeeRegistrationShift[]> {
    return this.employeeRegistrationService.getShiftsForFilter(workCenterId);
  }

  public getShiftsForFilterApproval(workCenterId: number, approvalReportDate: string): Observable<EmployeeRegistrationShift[]> {
    const params: EmployeeRegistrationService.GetShiftsForFilterApprovalParams = {
      workCenterId,
      approvalReportDate
    };
    return this.employeeRegistrationService.getShiftsForFilterApproval(params);
  }

  public getShiftsForFilterShiftReport(workCenterId: number, shiftId: number): Observable<EmployeeRegistrationShift[]> {
    const params: EmployeeRegistrationService.GetShiftsForFilterShiftReportParams = {
      workCenterId,
      shiftId
    };
    return this.employeeRegistrationService.getShiftsForFilterShiftReport(params);
  }

  public getHistory(workCenterId: number, shiftIds: number[]): Observable<EmployeeRegistrationHistory[]> {
    const params: EmployeeRegistrationService.GetHistoryParams = {
      workCenterId,
      shiftIds
    };
    return this.employeeRegistrationService.getHistory(params);
  }

  public employeeLogin(
    employeeId: number,
    loginTime: string,
    note?: string,
    registrationPurpose?: string,
    role?: EmployeeRole,
    workCenterId?: number
  ): Observable<null> {
    const _param: EmployeeRegistrationLoginEntry = {
      employeeId,
      loginTime,
      note,
      registrationPurpose,
      role,
      workCenterId
    };

    return this.employeeRegistrationService.login(_param);
  }

  public updateRegistration(employeeId: number, note?: string, role?: EmployeeRole, workCenterId?: number): Observable<null> {
    const param: EmployeeRegistrationUpdateEntry = {
      employeeRegistrationId: employeeId,
      note,
      role,
      workCenterId
    };

    return this.employeeRegistrationService.updateRegistration(param);
  }

  public employeeLogout(employeeId: number, workCenterId: number): Observable<null> {
    const _param: EmployeeRegistrationLogoutEntry = {
      employeeId,
      workCenterId
    };

    return this.employeeRegistrationService.logout(_param);
  }

  public getEmployees(): Observable<EmployeeInfo[]> {
    return this.employeeRegistrationService.getEmployees();
  }
}
