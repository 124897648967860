import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ArticleDescription, EmployeeInfo, ProductionOrderManualModeFinishingDataViewModel, Quantity } from 'chronos-core-client';
import { FormArray, FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EmployeeRegistrationDsService } from '@app/core/data-services';
import { TranslateService } from '@ngx-translate/core';
import { ColumnType, StatusPanel } from '../../models';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-multi-user-worktime-table',
  templateUrl: './multi-user-worktime-table.component.html',
  styleUrls: ['./multi-user-worktime-table.component.scss']
})
export class MultiUserWorktimeTableComponent implements OnInit, OnDestroy {
  @Input() public manualModeFinishData: ProductionOrderManualModeFinishingDataViewModel;
  @Input() public form: UntypedFormGroup;
  @Input() public article?: ArticleDescription;
  @Output() public quantityPanelChanged = new EventEmitter<StatusPanel>();
  @Output() public formStatusChanged = new EventEmitter<boolean>();

  public minValue = 0;
  public maxMinutes = 59;
  private readonly HOURS = 24;

  public employeesSuggestionList: EmployeeInfo[];
  public employees: EmployeeInfo[];
  public formGroupGrossQuantity: Quantity = { value: 0, unitId: '' };
  public orderGrossQuantity: Quantity;
  public duration = '00 h 00 min';
  public qtyStatusPanel: StatusPanel;
  public readonly COLUMN_TYPE = ColumnType;
  public maxHours = this.HOURS;

  private subscriptions = new Subscription();

  public readonly TEXT_MINUTES = 'minutes';
  public readonly TEXT_HOURS = 'hours';
  public readonly INPUT_STYLE = { width: '5em', textAlign: 'end' };
  public readonly INPUT_STYLE_QTY = { width: '8em', textAlign: 'end' };
  public readonly INPUT_STYLE_AUTOCOMPLETE = { width: '15em' };
  public readonly QUANTITY_MAX_LIMIT = 99999999;
  public readonly HOURS_MAX_LIMIT = 838;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private employeeRegistrationDsService: EmployeeRegistrationDsService,
    private translate: TranslateService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.employeeRegistrationDsService
        .getEmployees()
        .pipe(
          map((employees) => {
            this.employees = employees;
          })
        )
        .subscribe(() => {
          this.initModalForm(this.manualModeFinishData);
        })
    );
  }

  public get rows(): FormArray {
    return this.form.get('rows') as FormArray;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public addFormRow(hours: number, minutes: number, quantity: number, employee: object) {
    const form = this.formBuilder.group(
      {
        employee: [employee, [Validators.required]],
        hours: [hours, [Validators.required, Validators.min(0)]],
        minutes: [minutes, [Validators.required, Validators.min(0)]],
        quantity: [quantity, [Validators.required, Validators.min(1)]]
      },
      { validators: validateTime }
    );

    this.rows.push(form);
    this.formStatusChanged.emit(this.form.valid && this.formGroupGrossQuantity?.value === this.orderGrossQuantity?.value);
  }

  public removeRow(index: number): void {
    if (this.rows.length > 1) {
      this.rows.removeAt(index);
    }

    this.calculateFooterValues();
  }

  public filterAutoCompleteEmployees(event): void {
    const empList = this.form.value.rows.map((element) => ({
      employee: typeof element.employee === 'object' && element.employee !== null ? element.employee.name : element.employee
    }));

    this.employeesSuggestionList = this.employees.filter((employee) => employee.name.toLowerCase().includes(event.query.toLowerCase()));

    this.employeesSuggestionList = this.employeesSuggestionList.filter((empA) => !empList?.some((empB) => empA.name === empB.employee));
  }

  public onModelChange(index: number, coulumnType: ColumnType) {
    switch (coulumnType) {
      case ColumnType.EMPLOYEE:
        console.info('EMPLOYEENAME case executed');
        break;
      case ColumnType.HOURS:
        const hoursControl = this.rows.at(index).get('hours') as FormControl;
        if (hoursControl.value > this.HOURS_MAX_LIMIT) {
          hoursControl.setValue(this.HOURS_MAX_LIMIT);
          hoursControl.updateValueAndValidity();
        }
        break;
      case ColumnType.MINUTES:
        const minutesControl = this.rows.at(index).get('minutes') as FormControl;
        if (minutesControl.value > this.maxMinutes) {
          minutesControl.setValue(this.maxMinutes);
          minutesControl.updateValueAndValidity();
        }
        break;
      case ColumnType.QUANTITY:
        console.info('QUANTITY case executed');
        break;
      default:
        console.info('default case executed');
    }

    this.calculateFooterValues();
  }

  public isRowValid(index: number): boolean {
    const userRow = this.form.value.rows[index];
    return !(!!userRow.employee && (userRow.hours > 0 || userRow.minutes > 0) && userRow.quantity > 0);
  }

  private calculateFooterValues() {
    const formData = this.form.value;
    let workerCount = 0;
    let min = 0;
    let hours = 0;
    let quantity = 0;
    let totalMinutes = 0;

    formData.rows.map((element) => {
      workerCount = workerCount + 1;
      min = min + element.minutes;
      hours = hours + element.hours;
      quantity = quantity + element.quantity;
    });

    totalMinutes = hours * 60 + min;
    const displayHours = Math.floor(totalMinutes / 60);
    const displayMinutes = totalMinutes % 60;

    this.duration = `${displayHours}  ${this.translate.instant('FINISH_ORDER_MODAL.HOUR')}
    ${displayMinutes.toString().padStart(2, '0')} ${this.translate.instant('FINISH_ORDER_MODAL.MINUTE')}`;
    this.formGroupGrossQuantity = { value: quantity, unitId: 'PCS' };

    // set status panel
    this.statusPanel(displayHours);
    this.formStatusChanged.emit(this.form.valid && this.formGroupGrossQuantity?.value === this.orderGrossQuantity?.value);
  }

  private statusPanel(hours: number) {
    const qty = this.orderGrossQuantity.value - this.formGroupGrossQuantity.value;
    this.qtyStatusPanel = {
      quantity: { value: qty, unitId: this.orderGrossQuantity.unitId },
      status: qty !== 0 ? (qty < 0 ? 'negative' : null) : 'success',
      totalHours: hours
    };

    this.quantityPanelChanged.emit(this.qtyStatusPanel);
  }

  private initModalForm(orderFinishData: ProductionOrderManualModeFinishingDataViewModel): void {
    this.formGroupGrossQuantity = { value: 0, unitId: orderFinishData.orderGrossQuantity.unitId };
    this.orderGrossQuantity = orderFinishData.orderGrossQuantity;

    if (orderFinishData.runEmployeeWorkTimeData.length === 0) {
      this.addFormRow(0, 0, 0, null);
      this.statusPanel(0);
      return;
    }

    this.setFormValues(orderFinishData);
  }

  private setFormValues(orderFinishData: ProductionOrderManualModeFinishingDataViewModel) {
    let hours: any;
    let minutes: any;
    let quantity = 0;
    let employee = {};

    orderFinishData.runEmployeeWorkTimeData.forEach((item) => {
      if (item?.duration) {
        const obj = this.parseDuration(item?.duration);

        hours = obj.days * 24 + obj.hours;
        minutes = obj.minute;
      }

      if (item?.quantity) {
        quantity = item.quantity.value;
      }

      if (item?.employeeId) {
        employee = this.employees.find((x) => x.id === item.employeeId);
      }

      this.addFormRow(hours, minutes, quantity, employee);
    });

    this.form.updateValueAndValidity();
    this.calculateFooterValues();
  }

  private parseDuration(duration: string): any {
    const [daysAndHours, minutes, seconds] = duration.split(':');

    let days: number = 0;
    let hours: number;

    if (daysAndHours.includes('.')) {
      const [daysPart, hoursPart] = daysAndHours.split('.');
      days = parseInt(daysPart, 10);
      hours = parseInt(hoursPart, 10);
    } else {
      hours = parseInt(daysAndHours, 10);
    }

    const minute = parseInt(minutes, 10);

    return { days, hours, minute };
  }
}

function validateTime(formGroup: UntypedFormGroup): ValidationErrors | null {
  return formGroup.value.minutes === formGroup.value.hours && formGroup.value.hours === 0 ? { invalidTime: true } : null;
}
