import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { KpiValue } from 'chronos-core-client';
import { KpiStore } from './kpi.store';

@Injectable()
export class KpiService {
  constructor(private kpiStore: KpiStore) {}

  @transaction()
  public updateKpis(kpis: KpiValue[]): void {
    this.kpiStore.setLoading(true);
    this.kpiStore.set(kpis);
    this.kpiStore.setLoading(false);
  }
}
