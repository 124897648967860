<div class="p-grid">
  <div class="p-col-7">
    <lib-material-status [article]="article" [title]="'CREATE_PALLET_MODAL.ACTIVE_MATERIAL' | translate"></lib-material-status>
  </div>
  <div class="p-col-5">
    <div class="p-grid">
      <div class="p-col-2"></div>
      <div *ngIf="qtyStatusPanel" class="p-col-10">
        <app-status-panel
          [label]="'FINISH_ORDER_MODAL.QTY_TO_ASSIGN' | translate"
          [value]="qtyStatusPanel.quantity | formatQuantity : false"
          [unitId]="qtyStatusPanel.quantity.unitId"
          [status]="qtyStatusPanel.status"
        ></app-status-panel>
      </div>
    </div>
  </div>
</div>
<div class="multiuser-table-box">
  <div [formGroup]="form" class="multiuser-table">
    <div class="p-d-flex p-flex-nowrap multiuser-table__row form-header">
      <div class="form-header--employeename">{{ 'FINISH_ORDER_MODAL.EMPLOYEES' | translate }}</div>
      <div class="form-header--duration">{{ 'FINISH_ORDER_MODAL.DURATION' | translate }}</div>
      <div class="form-header--quantity">{{ 'FINISH_ORDER_MODAL.QUANTITY' | translate }}</div>
    </div>
    <div formArrayName="rows" class="multiuser-table-box__row-container">
      <div
        *ngFor="let row of rows.controls; let i = index"
        [formGroupName]="i"
        class="p-d-flex p-flex-nowrap multiuser-table__row"
        [ngClass]="isRowValid(i) ? 'multiuser-table__row--required' : ''"
      >
        <div class="form-group--grow-4">
          <div class="form-group">
            <div class="form-control">
              <p-autoComplete
                [inputStyle]="INPUT_STYLE_AUTOCOMPLETE"
                id="employeeAutoComplete"
                formControlName="employee"
                [suggestions]="employeesSuggestionList"
                (completeMethod)="filterAutoCompleteEmployees($event)"
                [forceSelection]="true"
                field="name"
                [autofocus]="true"
                [showClear]="true"
                [dropdown]="true"
                [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
                [appendTo]="'body'"
                (ngModelChange)="onModelChange(i, COLUMN_TYPE.EMPLOYEE)"
              ></p-autoComplete>
            </div>
          </div>
        </div>
        <div class="form-group--grow-4">
          <lib-input-number
            [className]="'form-control--time'"
            [inputStyle]="INPUT_STYLE"
            [formControlName]="'hours'"
            unit="h"
            [min]="minValue"
            [max]="HOURS_MAX_LIMIT"
            (ngModelChange)="onModelChange(i, COLUMN_TYPE.HOURS)"
          ></lib-input-number>

          <lib-input-number
            [inputStyle]="INPUT_STYLE"
            [formControlName]="'minutes'"
            unit="min"
            [min]="minValue"
            [max]="maxMinutes"
            (ngModelChange)="onModelChange(i, COLUMN_TYPE.MINUTES)"
          ></lib-input-number>
        </div>
        <div class="form-group--grow-3">
          <div class="form-group">
            <div class="form-control form-control--quantity">
              <lib-input-number
                [inputStyle]="INPUT_STYLE_QTY"
                [formControlName]="'quantity'"
                [unit]="orderGrossQuantity.unitId"
                [min]="0"
                [max]="QUANTITY_MAX_LIMIT"
                (ngModelChange)="onModelChange(i, COLUMN_TYPE.QUANTITY)"
              ></lib-input-number>
            </div>
          </div>
        </div>
        <div class="form-group--grow-1">
          <lib-button [typeClass]="'button--primary'" [label]="'-'" (clicked)="removeRow(i)" [disabled]="rows.length === 1"></lib-button>
        </div>
      </div>
      <lib-button class="button-padding" [label]="'FINISH_ORDER_MODAL.ADD_EMPLOYEE'" (clicked)="addFormRow(0, 0, 0, null)"></lib-button>
    </div>
    <div class="p-d-flex p-flex-nowrap multiuser-table__row form-footer">
      <div class="form-footer--sum">{{ 'FINISH_ORDER_MODAL.SUM' | translate }}</div>
      <div class="form-footer--duration">{{ duration }}</div>
      <div class="form-footer--quantity">{{ formGroupGrossQuantity | formatQuantity }}</div>
    </div>
  </div>
</div>
