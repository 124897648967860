<div [ngClass]="backgroundColorClass" class="upper-part">
  <label class="labelText">{{ speedUnit }}</label>
  <div class="value" [ngClass.xs]="{ value: false, 'value-mobile': true }" [ngClass.sm]="{ value: false, 'value-mobile': true }">
    {{ valuePropertyBag.speed | formatQuantity : false }}
  </div>
  <div class="kpi">
    <div class="kpi-label"></div>
    <div class="kpi-value ellipsis"></div>
  </div>
</div>

<div class="bottom-background-color lower-part">
  <div class="labelText">
    <span *ngIf="valuePropertyBag.targetSpeed">{{ 'SPEED_MONITOR.TARGET_SPEED' | translate }}</span>
    <span *ngIf="!valuePropertyBag.targetSpeed">{{ 'SPEED_MONITOR.MAX_MACHINE_SPEED' | translate }}</span>
  </div>
  <div
    class="target-value"
    [ngClass.xs]="{ 'target-value': false, 'target-value-mobile': true }"
    [ngClass.sm]="{ 'target-value': false, 'target-value-mobile': true }"
  >
    <span *ngIf="valuePropertyBag.targetSpeed">{{ valuePropertyBag.targetSpeed | formatQuantity : false }}</span>
    <span *ngIf="!valuePropertyBag.targetSpeed">{{ valuePropertyBag.speed | formatQuantity : false }}</span>
  </div>
  <lib-kpi-display [valuePropertyBag]="valuePropertyBag" [currentKpi]="currentKpi"></lib-kpi-display>
</div>
