import { StatusPanelType } from '@app/shared/components';
import { Quantity } from 'chronos-core-client';

export enum ColumnType {
  EMPLOYEE,
  MINUTES,
  HOURS,
  QUANTITY
}

export type StatusPanel = {
  label?: string;
  quantity: Quantity;
  status: StatusPanelType;
  totalHours?: number;
};
