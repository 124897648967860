<section class="time-edit">
  <div class="time-edit__header">
    <ng-content select="app-time-edit-header"></ng-content>
  </div>
  <div class="time-edit__content">
    <ng-content select="app-time-edit-content"></ng-content>
  </div>
  <div class="time-edit__empty"></div>
  <div class="edit-multi-user">
    <ng-content select="app-multi-user-worktime-table"></ng-content>
  </div>
  <div>
    <ng-content select="lib-modal-footer"></ng-content>
  </div>
</section>
