import { NgModule } from '@angular/core';
import { OrderStartModalComponent } from './components/order-start-modal/order-start-modal.component';
import { SharedModule } from '@app/shared/shared.module';
import { TimeEditTemplateComponent } from './components/time-edit-template/time-edit-template.component';
import { TimeEditHeaderComponent } from './components/time-edit-header/time-edit-header.component';
import { TimeEditContentComponent } from './components/time-edit-content/time-edit-content.component';
import { CreatePalletModalComponent } from '@app/modules/manual-mode/components/create-pallet-modal/create-pallet-modal.component';
import { ContainerPalletModule, UtcToLocalPipe } from 'chronos-shared';
import { OrderFinishModalComponent } from './components/order-finish-modal/order-finish-modal.component';
import { OrderFinishSimpleModalComponent } from './components/order-finish-simple-modal/order-finish-simple-modal.component';
import { DecimalPipe } from '@angular/common';
import { OrderFinishMultiUserModalComponent } from './components/order-finish-multiuser-modal/order-finish-multiuser-modal.component';
import { MultiUserWorktimeTableComponent } from './components/multi-user-worktime-table/multi-user-worktime-table.component';

@NgModule({
  declarations: [
    CreatePalletModalComponent,
    OrderStartModalComponent,
    TimeEditTemplateComponent,
    TimeEditHeaderComponent,
    TimeEditContentComponent,
    OrderFinishModalComponent,
    OrderFinishSimpleModalComponent,
    OrderFinishMultiUserModalComponent,
    MultiUserWorktimeTableComponent
  ],
  imports: [SharedModule, ContainerPalletModule],
  providers: [UtcToLocalPipe, DecimalPipe]
})
export class ManualModeModule {}
