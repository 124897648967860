<section class="seconary-section scan">
  <div class="row">
    <div class="col-6">
      <div class="scan__container">
        <lib-scan-container
          [validationChanges$]="scanValidationChanges$"
          (mountButtonClicked)="scanAndMountTool($event)"
          [scanPlaceholderText]="'MOUNTING.SCAN_INPUT_PLACEHOLDER' | translate"
          [buttonLabel]="'MOUNTING.MOUNT' | translate"
          [selectInputOnEnter]="true"
          [capsLockWarningMessage]="'MOUNTING.CAPSLOCK_ON_WARNING' | translate"
        ></lib-scan-container>
      </div>
    </div>
    <div class="col-4"></div>
    <div class="col-2">
      <div class="warehouse-stock-button" (click)="openWarehouseStockModal()">
        <div>
          <i class="icon-warehouse warehouse-stock-button__icon"></i>
        </div>
        <div class="warehouse-stock-button__title">
          {{ 'MOUNTING.WAREHOUSE_STOCK' | translate }}
        </div>
      </div>
    </div>
  </div>
</section>
<section class="secondary-section overview">
  <div class="row">
    <h1 class="page-header">{{ 'MOUNTING.ACTIVE_ORDER' | translate }}</h1>
    <app-material-overview-table
      [tableData]="secondaryMaterial$ | async"
      [buttonTemplate]="overviewMaterialButtonTemplate"
      [buttonTemplateStyle]="{ width: '170px' }"
    ></app-material-overview-table>
  </div>
  <div class="row">
    <h1 class="page-header">{{ 'MOUNTING.MOUNTED_MATERIAL' | translate }}</h1>
    <app-mounted-material-table
      [tableData]="secondaryMountedMaterial$ | async"
      [buttonTemplate]="mountedMaterialButtonTemplate"
      [buttonTemplateStyle]="{ width: '170px' }"
    ></app-mounted-material-table>
  </div>
</section>

<ng-template #mountedMaterialButtonTemplate let-rowData>
  <ng-container *ngIf="rowData.displayMode === MaterialTableDataRowDisplayMode.Container">
    <lib-icon-button
      class="overview__reduce_icon"
      [iconClass]="'icon-unmount-pallet'"
      (clicked)="dismountMaterial(rowData)"
    ></lib-icon-button>
  </ng-container>
</ng-template>

<ng-template #overviewMaterialButtonTemplate let-rowData>
  <div class="overview__action-list">
    <ng-container *ngIf="rowData.displayMode === MaterialTableDataRowDisplayMode.BillOfMaterial; else batchButton">
      <ng-container [ngTemplateOutlet]="paperToolMount" [ngTemplateOutletContext]="{ $implicit: rowData }"></ng-container>
    </ng-container>
  </div>

  <ng-template #batchButton>
    <lib-icon-button
      class="overview__reduce_icon"
      [iconClass]="'icon-unmount-pallet'"
      (clicked)="dismountMaterial(rowData)"
    ></lib-icon-button>

    <ng-container [ngTemplateOutlet]="paperToolDismount" [ngTemplateOutletContext]="{ $implicit: rowData }"></ng-container>
  </ng-template>

  <ng-template #paperToolDismount let-rowData>
    <app-split-button *ngIf="rowData.isVirtualContainer" [items]="getDummyToolActions(rowData)" class="overview__action"></app-split-button>
  </ng-template>

  <ng-template #paperToolMount let-rowData>
    <app-split-button [items]="getToolActions(rowData)" class="overview__action"></app-split-button>
  </ng-template>
</ng-template>
