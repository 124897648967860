import { Component, Input } from '@angular/core';
import { ValuePropertyBag, KPI } from '../../models/speed-monitor.model';

@Component({
  selector: 'lib-kpi-display',
  templateUrl: './kpi-display.component.html',
  styleUrls: ['./kpi-display.component.scss']
})
export class KpiDisplayComponent {
  @Input() public valuePropertyBag: ValuePropertyBag;
  @Input() public currentKpi: KPI;
}
