import { Component, EventEmitter, OnDestroy, OnInit, Output, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ActiveOrderDsService } from '@app/core/data-services';
import { ActiveOrderDataQuery } from '@app/core/global-state';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { ListValue, LoadingNotificationService } from 'chronos-shared';
import { ProductionOrderSetupPhaseState, Quantity, WasteAssignment } from 'chronos-core-client';
import { DialogService } from 'primeng/dynamicdialog';
import { isNil } from 'ramda';
import { Observable, Subscription } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { QuantityAllocationService } from '../../../../services/quantity-allocation/quantity-allocation.service';
import { WasteAssignmentFormEvent } from '@app/shared/models';
import { ButtonItem, ChartMode, IMachineChartDialogConfiguration, MachineChartDialogComponent } from '@app/shared/components';
import { notificationTopic } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-quantity-allocation',
  templateUrl: './quantity-allocation.component.html',
  styleUrls: ['./quantity-allocation.component.scss']
})
export class QuantityAllocationComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Output() public validationChanged = new EventEmitter<boolean>();

  public reasonOptions$: Observable<ListValue[]>;
  public setupPhaseStatus: ProductionOrderSetupPhaseState;
  public wasteAllocations: WasteAssignment[];
  public counter: Quantity = { value: 0 };

  private readonly WASTE_ADD_LOADING_TOPIC = notificationTopic.wasteAssignmentAddAction;
  private activeProductionOrderId: number;
  private subscriptions = new Subscription();
  public taskButtons: ButtonItem[] = [];
  public loadingTopic: string;

  constructor(
    private quantityAllocationService: QuantityAllocationService,
    private activeOrderDsService: ActiveOrderDsService,
    private activeOrdersQuery: ActiveOrderDataQuery,
    private dialogService: DialogService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private translateService: TranslateService,
    private cdRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.activeProductionOrderId = this.activeOrderDsService.getActiveOrderId();
    this.reasonOptions$ = this.quantityAllocationService.getReasonOptions();
    this.subscriptions.add(
      this.activeOrdersQuery.setupPhaseStatus$.pipe(filter((setupPhaseStatus) => !!setupPhaseStatus)).subscribe((setupPhaseStatus) => {
        this.setupPhaseStatus = setupPhaseStatus;
        this.counter = {
          value: setupPhaseStatus.remainingSetupPhaseQuantity.value,
          unitId: setupPhaseStatus.remainingSetupPhaseQuantity.unitId
        };
        this.validationChanged.emit(this.counter.value === 0 && !isNil(setupPhaseStatus.setupEndTime));
        this.taskButtons = this.constructTaskButtons();
      })
    );
    this.subscriptions.add(
      this.activeOrdersQuery.wasteAllocations$.subscribe((wasteAllocations) => {
        this.wasteAllocations = wasteAllocations;
      })
    );
    this.subscriptions.add(this.quantityAllocationService.getSetupStateNotifications(this.activeProductionOrderId).subscribe());
    this.quantityAllocationService.getSetupWasteAssignments(this.activeProductionOrderId);
    this.quantityAllocationService.getSetupPhaseStatus(this.activeProductionOrderId);
  }

  public ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private constructTaskButtons(): ButtonItem[] {
    return [
      {
        command: () => {
          this.openMachineChart(false);
        },
        label: 'TASKS.EDIT_SETUP',
        visible: true,
        disabled: !this.setupPhaseStatus?.setupEndTime ? true : false
      }
    ];
  }

  public onRowAdded(wasteAssignment: WasteAssignmentFormEvent) {
    LoadingNotificationService.publish(this.WASTE_ADD_LOADING_TOPIC, true);
    this.quantityAllocationService
      .addWasteAssignment(this.activeProductionOrderId, wasteAssignment)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.WASTE_ADD_LOADING_TOPIC, false);
        })
      )
      .subscribe();
  }

  public onRowRemoved(id: number) {
    this.quantityAllocationService.deleteWasteAssignment(this.activeProductionOrderId, id).subscribe();
  }

  public openMachineChart(isEndTimeEnterManually?: boolean): void {
    this.dialogService.open(MachineChartDialogComponent, {
      width: isEndTimeEnterManually ? '25%' : '75%',
      styleClass: 'no-scroll-dialog',
      header: this.translateService.instant('MACHINE_CHART.EDIT_CHART_TITLE'),
      data: {
        workCenterId: this.activeWorkCenterService.getWorkCenterId(),
        chartMode: ChartMode.EditSetup,
        startTime: new Date(this.setupPhaseStatus.setupStartTime),
        endTime: new Date(this.setupPhaseStatus.setupEndTime),
        quantity: this.setupPhaseStatus.setupPhaseQuantity
      } as IMachineChartDialogConfiguration
    });
  }
}
