<div class="setup-top-background-color upper-part">
  <label class="labelText">{{ 'SPEED_MONITOR.SETUP_TIME' | translate }}</label>
  <div
    class="value"
    *ngIf="valuePropertyBag.duration"
    [ngClass.xs]="{ value: false, 'value-mobile': true }"
    [ngClass.sm]="{ value: false, 'value-mobile': true }"
  >
    {{ valuePropertyBag.duration | millisecondsToDuration }}
  </div>
  <div class="kpi">
    <div *ngIf="valuePropertyBag.mode === 'Large'" class="kpi-label">
      {{ 'SPEED_MONITOR.DOWNTIME_CODE' | translate }}
    </div>
    <div class="kpi-value ellipsis">
      <label *ngIf="valuePropertyBag.reasonCode">({{ valuePropertyBag.reasonCode }})</label>
      &nbsp;
      <label *ngIf="valuePropertyBag.mode === 'Large'">{{ valuePropertyBag.reasonText | ellipsis }}</label>
    </div>
  </div>
</div>

<div class="bottom-background-color lower-part">
  <div class="labelText">
    {{ 'SPEED_MONITOR.PLANNED_SETUP_TIME' | translate }}
  </div>
  <div
    class="target-value"
    [ngClass.xs]="{ 'target-value': false, 'target-value-mobile': true }"
    [ngClass.sm]="{ 'target-value': false, 'target-value-mobile': true }"
  >
    {{ valuePropertyBag.estimatedDuration }}
  </div>
  <lib-kpi-display [valuePropertyBag]="valuePropertyBag" [currentKpi]="currentKpi"></lib-kpi-display>
</div>
